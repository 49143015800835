<template>
  <section class="d-block d-sm-none" v-if="mostDiscount && mostDiscount.length">
    <div class="mt-5">
      <div class="container d-flex align-items-center justify-content-between">
        <h4 class="fontsize20 text-color-444 font-weight-bold">پیشنهاد روز</h4>
        <router-link
          class="fontsize15 text-color-themeRed"
          to="/products?sort=most_discount"
          >مشاهده همه</router-link
        >
      </div>
      <div
        class="d-flex flex-column align-center mt-4 today-suggest-container"
        style="gap: 10px"
      >
        <newProduct
          v-for="(product, index) in mostDiscount"
          :key="index"
          :product="product"
        ></newProduct>
      </div>
    </div>
  </section>
</template>

<script>
import newProduct from "@/parts/Front/components/product/NewProduct";

export default {
  name: "mostDiscountProduct",
  inject: ["mediaQueries"],
  components: {
    newProduct,
  },
  computed: {
    showCount() {
      if (this.mediaQueries.xxxLarge) {
        return 8;
      }
      if (this.mediaQueries.xxLarge) {
        return 8;
      }
      if (this.mediaQueries.xLarge) {
        return 8;
      }
      if (this.mediaQueries.medium) {
        return 6;
      }
      return 6;
    },
    mostDiscount() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.getEnough(
          this.$store.getters["front/getHomeData"].mostDiscount
        );
      }
    },
  },
  methods: {
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
  },
};
</script>

<style scoped>
.today-suggest-container {
  max-height: 450px;
  overflow-y: scroll !important;
  -ms-overflow-style: none; /* برای edge */
  scrollbar-width: none; /* برای فایرفاکس*/
}
.today-suggest-container::-webkit-scrollbar {
  display: none; /* برای فایرفاکس*/
}
</style>
