<template>
  <section>
    <div class="faq ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="faq-text-area pr-20">
              <div class="default-section-title">
                <h6>سوالات متداول</h6>
                <h3>سوال متداول مشتریان</h3>
              </div>
              <div class="faq-area">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <details>
                      <summary
                        class="font-bold fontsize-medium faq-accordion-title"
                      >
                        چرا از آریا گستر خرید کنیم؟
                      </summary>
                      <div class="faq-accordion-body">
                        در تمامی 12 سال تجربه کاری در زمینه لوازم جانبی موبایل
                        آشنا به بازار های داخلی و خارجی هستیم و تمامی کالاها با
                        در نظر گرفتن نیاز بازار موجود می شود دلیل دیگری که چرا
                        آریا گستر را انتخاب کنید کیفیت بالا در ازای قیمت پایین
                        کالا می باشد که در الویت های همیشگی تیم آریا گستر بوده و
                        خواهد بود.
                      </div>
                    </details>
                  </div>
                  <div class="accordion-item">
                    <details>
                      <summary
                        class="font-bold fontsize-medium faq-accordion-title"
                      >
                        آریا گستر چگونه کیفیت کالا را در ازای قیمت پایین تضمین
                        می کند؟
                      </summary>
                      <div class="faq-accordion-body">
                        گارانتی شدن تمامی کالا ها دال بر این ادای ماست که قیمت
                        پایین را با کیفیتی بالا در اختیار شما بزرگواران قرار
                        دهیم.
                      </div>
                    </details>
                  </div>
                  <div class="accordion-item">
                    <details>
                      <summary
                        class="font-bold fontsize-medium faq-accordion-title"
                      >
                        نحوه خرید به چه صورت است؟
                      </summary>
                      <div class="faq-accordion-body">
                        1.خرید حضوری به آدرس گرگان خیابان سرخواجه مجتمع آفتاب دو
                        طبقه سوم واحد 525 2.خرید تلفنی با 10 اپراتور فعال 3.سایت
                        2.شرایط پرداخت و ثبت سفارش خرید بالای یک میلیون تومان
                        الزامی می باشد. 1/3 مبلغ خرید به صورت نقدی پرداخت گردد و
                        مابقی حساب به صورت چک به مدت 35 الی 45 روزه می باشد.
                      </div>
                    </details>
                  </div>
                  <!-- <div class="accordion-item">
                    <details>
                      <summary
                        class="font-bold fontsize-medium faq-accordion-title"
                      >
                        چگونه نمایندگی آریا را داشته باشیم؟
                      </summary>
                      <div class="faq-accordion-body">
                        مجموعه آریا گستر در نظر دارد تا بستری مناسب و کارآمد را
                        برای هموطنان عزیز میسر نماید لذا جهت اطلاع از شرایط اخذ
                        نمایندگی میتوانید فرم اخذ نمایندگی آریا گستر را کامل
                        نمایید تا کارشناسان واحد نمایندگی با شما تماس بگیرند.
                      </div>
                    </details>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="faq-img-area">
              <img
                :src="`https://api.ariyajanebi.ir/storage/${settings?.settings?.landing?.landing_faq}`"
                alt="تصویر سوالات متداول لندینگ"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.getters["front/getLandingHome"];
    },
  },
};
</script>

<style scoped>
.faq .faq-area .accordion .accordion-item .faq-accordion-body {
  line-height: 2rem;
  border-radius: 0px 0px 5px 5px;
  padding: 12px 22px 12px 22px;
  box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.2);
}
.faq .faq-area .accordion .accordion-item .faq-accordion-title {
  padding: 15px 15px;
  border-radius: 5px 5px 0 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
