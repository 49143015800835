<template>
  <section
    id="storyCategorySection"
    v-if="categories && categories.length"
    class="products-categories d-flex d-sm-none mx-sm-3 mr-2 flex-nowrap justify-content-start bg-transparent"
    style="gap: 1rem"
  >
    <template v-for="(category, index) in categories">
      <article :key="category.id">
        <router-link
          :to="{
            name: 'product.category',
            params: { category: category.id },
          }"
          class="d-flex flex-column align-items-center"
        >
          <span class="products-categories-img rounded-circle">
            <img
              class="rounded-circle"
              v-if="category.image"
              width="60"
              height="60"
              :src="category.image.url"
              :alt="category.title"
            />
          </span>
          <div class="text-center">
            <h6
              class="text-color-444 font-weight-light fontsize14 mt-2 text-nowrap"
            >
              {{ category.title }}
            </h6>
          </div>
        </router-link>
      </article>
    </template>
  </section>
</template>

<script>
export default {
  name: "storyCategories",
  inject: ["mediaQueries"],
  computed: {
    showCount() {
      if (this.mediaQueries.xxxLarge) {
        return 10;
      }
      if (this.mediaQueries.xxLarge) {
        return 9;
      }
      return 9;
    },
    // دریافت دسته بندی ویژه
    categories() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return null;
      } else {
        return this.getEnough(
          this.$store.getters["front/getHomeData"].special_categories
        );
      }
    },
  },
  methods: {
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
  },
};
</script>

<style scoped>
.products-categories-img img {
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, purple, orange) border-box;
  border: 3px solid transparent;
  border-radius: 50px;
}
#storyCategorySection {
  overflow-x: scroll !important;
  max-width: 99%;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  -ms-overflow-style: none;  /* برای edge */
  scrollbar-width: none; /* برای فایرفاکس*/
}
#storyCategorySection::-webkit-scrollbar {
  display: none;/* برای فایرفاکس*/

}
</style>
