<template>
  
<svg id="User_Icon" data-name="User Icon" xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 31.203 31.203">
  <g id="User_Icon-2" data-name="User Icon">
    <path id="Vector-2" data-name="Vector" d="M6.059,3.029A3.029,3.029,0,1,1,3.029,0,3.029,3.029,0,0,1,6.059,3.029Z" transform="translate(12.572 8.243)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.8"/>
    <path id="Vector-3" data-name="Vector" d="M10.4,4.238C10.4,1.9,8.074,0,5.2,0S0,1.9,0,4.238" transform="translate(10.401 17.422)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.8"/>
    <path id="Vector-4" data-name="Vector" d="M0,0H31.2V31.2H0Z" fill="none" opacity="0"/>
  </g>
</svg>
</template>

<script>
export default {};
</script>

<style></style>
