<template>
  <section>
    <template v-if="!registerHash">
      <landing-slider />
      <landing-categories />
      <landing-aboutVue />
      <landing-features />
    </template>
    <landing-membership :hashMode="registerHash" />
    <template v-if="!registerHash">
      <landing-products />
    </template>


    <!-- این بخش همون مودال اول سایت هست  -->
    <!-- <div id="announcementModal">
      <transition>
        <div @click="closeModal" v-if="showModal" id="announcementBody">
          <div class="d-flex align-items-center justify-content-center" id="announcementContent">
            <div class="col-10 col-lg-8">
              <video class="w-100" autoplay muted loop>
                <source
                  src="../../../assets/images/advertise/firstOrder.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </transition>
    </div> -->
  </section>
</template>

<script>
import LandingCategories from "@/parts/Front/components/Landing/Index/LandingCategories.vue";
import LandingMembership from "@/parts/Front/components/Landing/Index/LandingMembership.vue";
import LandingProducts from "@/parts/Front/components/Landing/Index/LandingProducts.vue";
import LandingSlider from "@/parts/Front/components/Landing/Index/LandingSlider.vue";
import LandingFeatures from "@/parts/Front/components/Landing/Index/LandingFeatures.vue";
import LandingAboutVue from "@/parts/Front/components/Landing/AboutUs/LandingAbout.vue";
export default {
  mounted() {
    if (
      this.$route.hash != "" &&
      this.$route.hash.split("#")[1] == "register"
    ) {
      this.registerHash = true;
    }
  },
  data() {
    return {
      registerHash: false,
      // showModal: true, 
    };
  },
  components: {
    LandingCategories,
    LandingMembership,
    LandingSlider,
    LandingProducts,
    LandingFeatures,
    LandingAboutVue,
  },
  methods: {
    // closeModal() {
    //   this.showModal = false;
    // },
  },
};
</script>

<style scoped>
#announcementModal {
  position: relative;
}
#announcementBody {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000007a;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
