<template>
  <div class="cart-btn bg-none ml-2 headerIcon">
    <cartSvg />
    <span
      v-if="quantity != 0"
      class="rounded-circle text-white fontsize12 weight-bold"
    >
      {{ quantity }}</span
    >
  </div>
</template>
<script>
import cartSvg from "../svg/Cart.vue";

import { cartListsOpen } from "@/libraries/functions";

export default {
  components: {
    cartSvg,
  },
  computed: {
    cart() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.cart.cartItems != null
      ) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },
    quantity() {
      if (this.cart) {
        return this.cart.cartItems.reduce((sum, item) => {
          sum += item.quantity;
          return sum;
        }, 0);
      } else {
        return 0;
      }
    },
  },
  methods: {
    cartListsOpen() {
      cartListsOpen();
      this.$router.push(this.$route.fullPath + "#cart-modal");
    },
  },
};
</script>
<style scoped>
.headerIcon .info {
  position: unset;
  left: unset;
  top: unset;
  font-size: 11px;
}
</style>
